<template>
  <div>
    <div class="container">
      <article>
        <h1 class="mb-4">{{ $t("pages.dashboard.title") }}</h1>
        <div class="d-flex p-2 table-responsive">
          <responsible-pay-roll-for-sign-widget />
          <employee-working-widget class="ml-4" />
          <employee-vacations-widget class="ml-4" />
          <employee-sick-widget class="ml-4" />
          <employee-resting-widget class="ml-4" />
          <confirm-waiting-absence-widget class="ml-4" />
        </div>
      </article>
    </div>

    <article class="mt-5 plan">
      <h1 class="mb-4">{{ $t("pages.dashboard.responsible.plan_employees") }}</h1>

      <plan-component :defaultType="typeDayCalendar" />
    </article>
    <work-shift-detail-modal />
  </div>
</template>

<script>
import ResponsiblePayRollForSignWidget from "@/pages/dashboard/widgets/ResponsiblePayRollForSignWidget.vue";
import { PlanComponent } from "@/pages/responsible/planing/general/components";
import { DATE_PERIOD_DAY } from "@/components/form";
import WorkShiftDetailModal from "@/pages/responsible/planing/workshift/components/WorkShiftDetailModal.vue";

import {
  EmployeeWorkingWidget,
  EmployeeRestingWidget,
  EmployeeVacationsWidget,
  EmployeeSickWidget,
} from "@/pages/responsible/planing/widgets";
import { ConfirmWaitingAbsenceWidget } from "@/pages/responsible/planing/absences/widgets";

export default {
  components: {
    ResponsiblePayRollForSignWidget,
    EmployeeWorkingWidget,
    EmployeeRestingWidget,
    EmployeeVacationsWidget,
    EmployeeSickWidget,
    PlanComponent,
    ConfirmWaitingAbsenceWidget,
    WorkShiftDetailModal,
  },
  computed: {
    typeDayCalendar() {
      return DATE_PERIOD_DAY;
    },
  },
};
</script>
